<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button @click="onAdd" icon="el-icon-plus" size="mini" type="defalut">新建</el-button>
                <el-button @click="getData" icon="el-icon-refresh" size="mini">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="用户设置" />
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="名称">
                    <el-input clearable placeholder="请输入" v-model="search.name" />
                </el-form-item>
                <el-form-item label="电话">
                    <el-input placeholder="请输入" v-model="search.phone" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-button @click="getData" plain type="primary">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" border stripe size="mini" v-loading="loading" width="100%" height="100%"
                          :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }" width="55" />
                    <el-table-column label="用户名称" prop="name" width="" show-overflow-tooltip/>
                    <el-table-column label="登录账号" prop="username" width="" show-overflow-tooltip/>
                    <el-table-column label="联系电话" prop="data.phone" width="" show-overflow-tooltip/>
                    <el-table-column label="创建时间" prop="createdAt" :formatter="dateFormatter"  width="" show-overflow-tooltip/>
                    <el-table-column label="备注" prop="data.remark" width="" />
                    <el-table-column align="center" label="操作" width="240" >
                        <template slot-scope="scope">
                            <el-button  size="mini" @click="handleUpdate(scope.row)" >修改
                            </el-button>
                            <el-button  size="mini" @click="handelRest(scope.row)">重置密码
                            </el-button>
                            <el-button  size="mini" @click="handeDel(scope.row)" >删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination :current-page="table.page" :page-size="table.pageSize"
                           :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]" :total="table.total" @current-change="handlePageChange"
                           @size-change="handlePageSizeChange" layout="total, prev, pager, next, sizes" />
        </div>
        <el-dialog :visible.sync="dialogVisible" title="设置权限" width="50%">
            <el-form :model="permissionForm" label-width="80px" ref="permissionForm">
                <el-form-item label="访问权限">
                    <el-checkbox-group v-model="permissionForm.modules">
                        <el-checkbox label="故障预测" name="modules"></el-checkbox>
                        <el-checkbox label="测试数据" name="modules"></el-checkbox>
                        <el-checkbox label="测试结果" name="modules"></el-checkbox>
                        <el-checkbox label="系统设置" name="modules"></el-checkbox>
                        <el-checkbox label="文档支持" name="modules"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="操作权限">
                    <el-checkbox-group v-model="permissionForm.actions">
                        <el-checkbox label="查询" name="actions"></el-checkbox>
                        <el-checkbox label="添加" name="actions"></el-checkbox>
                        <el-checkbox label="修改" name="actions"></el-checkbox>
                        <el-checkbox label="删除" name="actions"></el-checkbox>
                        <el-checkbox label="导出" name="actions"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <span class="dialog-footer" slot="footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button @click="dialogVisible = false" type="primary">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="passwordVisible" title="重置密码" width="50%" >
            <el-form :model="passwordForm" label-width="80px" ref="passwordForm" :rules="rules">
                <el-form-item label="密码" prop="new_password" >
                    <el-input show-password type="password" v-model="passwordForm.new_password"></el-input>
                </el-form-item>
            </el-form>
            <span class="dialog-footer" slot="footer">
                <el-button @click="passwordVisible = false">取 消</el-button>
                <el-button @click="onSubmitRest" type="primary">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="Visible" title="修改/添加" width="50%">
            <el-form :model="form" :rules="rules" label-width="80px" ref="form">
                <el-form-item label="登录账号" prop="username">
                    <el-input v-model="form.username"></el-input>
                </el-form-item>
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password" v-if="operator=='add'">
                    <el-input show-password type="password" v-model="form.password"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="data.phone">
                    <el-input v-model="form.data.phone"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input :rows="2" type="textarea" v-model="form.data.remark"></el-input>
                </el-form-item>
            </el-form>
            <span class="dialog-footer" slot="footer">
                <el-button @click="Visible = false">取 消</el-button>
                <el-button @click="onSubmit" type="primary">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
export default {
    name: 'setting-user',
    path: '/main/setting/user',
    components: {},
    data() {
        return {
            Visible: false,
            loading: false,
            dialogVisible: false,
            passwordVisible: false,
            search: {},
            table: {
                data: [],
                total: 0,
                page: 1,
                pageSize: 20
            },
            operator: "add",
            permissionForm: { modules: [], actions: [] },
            passwordForm: {
                id:null,
                new_password:''
            },
            form: {
                name:"",
                username:"",
                password:"",
                data:{
                    phone:"",
                    remark:""
                }
            },
            rules: {
                name: [
                    {required: true, message: '此项为必填项', trigger: 'blur'}
                ],
                username: [
                    {required: true, message: '此项为必填项', trigger: 'blur'}
                ],
                password: [
                    {required: true, message: '此项为必填项', trigger: 'blur'}
                ],
                data: {
                    phone: [
                        {required: true, message: '此项为必填项', trigger: 'blur'}
                    ],
                },
                new_password: [
                    {required: true, message: '此项为必填项', trigger: 'blur'}
                ],
            },
        }
    },
    methods: {
        dateFormatter(v) {
            return new Date(v.createdAt).format('yyyy-MM-dd')
        },
        handelRest(row){
            this.passwordForm.new_password = ""
            this.passwordForm.id= row.id
            this.passwordVisible = true
        },
        onAdd(){
            this.operator = "add"
            this.form = {
                name:"",
                username:"",
                password:"",
                data:{
                    phone:"",
                    remark:""
                }
            }
            this.Visible=true
        },
        handleUpdate(row){
            this.operator = "edit"
            this.form = row
            if(!row.data){
                this.form.data = {
                    phone:"",
                    remark:""
                }
            }
            this.Visible = true
        },
        handeDel(row){
            this.$http.delete(`/user/${row.id}/`)
                .then(resp => {
                    if (resp.data.code == 200) {
                        this.$message.success(resp.data.msg)
                        this.getData()
                    }
                }).catch(err => {
                this.$message.error(err.message)
            }).finally(() => {
                this.loading = false
            })
        },
        handlePageChange(e) {
            this.table.page = e
            this.getData()
        },
        handlePageSizeChange(e) {
            this.table.pageSize = e
            this.getData()
        },
        async validateForms() {
            try {
                await this.$refs['form'].validate();
                // 执行其他逻辑
                return true;
            } catch (error) {
                return false;
            }
        },
        async onSubmit(){
            if (!await this.validateForms()) return false
            this.loading = true
            this.$http.post("/user", this.form)
                .then(resp => {
                    if (resp.data.code != 200) {
                        this.$message.error(resp.data.msg);
                    } else {
                        this.Visible = false
                        this.getData()
                    }
                }).catch(err => {
                this.$message.error(err.message);
            }).finally(() => {
                this.loading = false
            })
        },
        async onSubmitRest(){
            if (!this.$refs['passwordForm'].validate()) return false
            this.loading = true
            this.$http.post("/user/reset_password", this.passwordForm)
                .then(resp => {
                    if (resp.data.status != "success") {
                        this.$message.error(resp.data.msg);
                    } else {
                        this.passwordVisible = false
                    }
                }).catch(err => {
                this.$message.error(err.message);
            }).finally(() => {
                this.loading = false
            })
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/user?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.table.data = resp.data.rows
                this.table.total = resp.data.count
            }
            this.loading = false
        },
    },

    mounted() {
        this.getData()
    },
}
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 5px !important;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
    @media (max-width: 992px) {
        ::v-deep .el-dialog{
            width: 96% !important;
            /*margin: 0 10px;*/
            margin-top: 5vh !important;

        }
    }

    @media (max-width: 1200px) {
        ::v-deep .el-dialog{
            width: 90% !important;
            /*margin: 0 10px;*/
            /*margin-top: 5vh !important;*/

        }
    }
</style>
